import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from '../../services/axios';
import { useCookies } from 'react-cookie';
import Loader from '../Loader';
import { PreviewContent } from './styled';
import VideoUpload from './uploadVideo';
import { Theme } from 'src/styles/Theme';


const LatestNews: React.FC = () => {
  const [imageFile, setImageFile] = useState({
    selectedFile: '',
    type: ''
  });
  const nav = useHistory();
  const [imageName, setImageName] = useState('');
  const [title, setTitle] = useState<string>('');
  const [fullLegend, setFullLegend] = useState<any>(EditorState.createEmpty());
  const [prevLegend, setPrevLegend] = useState('');
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [myPreview, setMyPreview] = useState<boolean>(false);

  const [file, setFile] = useState<File | null>(null);
  async function createAnnouncement() {
    const htmlDescription = draftToHtml(
      convertToRaw(fullLegend.getCurrentContent())
    );
    const dataFile = new FormData();
    console.log(file)
    if (file) {
      dataFile.append('file', file);
    }

    await axios.post('/api/v1/latest-news/uploadFile', dataFile, {timeout: 0}).then( res => {
      const data = {
        image: res.data.type === 'image' ? res.data.url : '',
        video: res.data.type === 'video' ? res.data.url : '',
        prev_legend: prevLegend,
        full_legend: htmlDescription,
        title: title,
        idea: false
      };
      axios
        .post('/api/v1/latest-news/create', data, { headers: {
          ContentType: 'multipart/form-data'
        }, timeout: 0})
        .then((res) => {
          setTitle('');
          setPrevLegend('');
          setImageName('');
          setImageFile({
            selectedFile: '',
            type: ''
          });
          setFullLegend('');
          setConfirmation(false);
          alert('Latest News created');
          nav.push('/announcements');
        })
        .catch((err) => {
          console.log(err);
        });
    })

  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };


  window.onclick = function (event: any) {
    if (event.target.className === 'modal') {
      setConfirmation(false);
    }
  };

  const myModal = (
    <div
      id='myModal'
      style={{ display: confirmation ? 'block' : 'none' }}
      className='modal'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <span onClick={() => setConfirmation(false)} className='close'>
            &times;
          </span>
          <h2>
            You are about to publish a new Latest News. This will go to the Home
            Page and Latest News feed for all users to view.
          </h2>
        </div>
        <div className='modal-confirmation'>
          <form>
            {modalLoading ? (
              <Loader />
            ) : (
              <>
                <button
                  type='button'
                  onClick={createAnnouncement}
                  className='cancelbtn'
                >
                  Yes
                </button>
                <button
                  type='button'
                  onClick={() => setConfirmation(false)}
                  className='deletebtn'
                >
                  No
                </button>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );

  const preview = (
    <PreviewContent
      style={{ display: myPreview ? 'block' : 'none', maxWidth: '100%' }}
    >
      <span onClick={() => setMyPreview(false)} className='close'>
        &times;
      </span>
      <h1>Title:</h1>
      <h1 className='primary'>{title}</h1>
      <hr />
      <h1>Preview:</h1>
      <h1 className='primary'>{prevLegend}</h1>
      <hr />
      <h2>Full Article: </h2>
      {fullLegend && (
        <div
          dangerouslySetInnerHTML={{
            __html: draftToHtml(convertToRaw(fullLegend.getCurrentContent()))
          }}
        ></div>
      )}
    </PreviewContent>
  );

  return (
    <div className='new-announcement'>
      <h1>FEG Latest News</h1>
      <div className='utils'>
        <h4>Title</h4>
        <input
          type='text'
          name='nameInput'
          value={title}
          maxLength={250}
          onChange={(e) => setTitle(e.target.value)}
        />
        <h4>Preview Field</h4>
        <input
          type='text'
          name='nameInput'
          value={prevLegend}
          maxLength={250}
          onChange={(e) => setPrevLegend(e.target.value)}
        />
        <h4>Picture or Video</h4>
        <label htmlFor="video-upload" style={{padding: 10, background: `#efefef`, color: '#4c4c4c', borderRadius: 4, marginRight: 10, cursor: 'pointer'}}>Choose a video or photo to upload:</label>
        
        {file?.name}
      <input id="video-upload" type="file" onChange={handleFileChange} />
      <p style={{marginTop: 15}}>The system does not accept files larger than 1TB.</p>
        <h4>Full Article</h4>

        <Editor
          editorState={fullLegend}
          wrapperClassName='demo-wrapper'
          stripPastedStyles={true}
          editorClassName='demo-editor'
          onEditorStateChange={(e) => {
            setFullLegend(e);
          }}
        />
      </div>

      <button className='button' onClick={() => setConfirmation(true)}>
        Publish
      </button>
      {myModal}
      {preview}
      <button
        onClick={() => {
          setMyPreview(myPreview ? false : true);
        }}
      >
        Preview Content
      </button>
    </div>
  );
};

export default LatestNews;
