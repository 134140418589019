import React, { useEffect, useState } from 'react';
import { Container, Grid } from './styled';
import axios, { api } from 'src/services/axios';
import { Avatar, Input } from '@material-ui/core';
import logoChat from '../../images/logoChat.jpeg'
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import jwt from 'jsonwebtoken';
import { Theme } from 'src/styles/Theme';
import { FaHeart } from 'react-icons/fa';
import { semanasOuDiasPassados } from './weeks';
import { BiTrash } from 'react-icons/bi';

interface CommentsLatestNews {
  id:string;
  id_remetente:string; 
  latest_news_id:string;
  content: string;
  type: string;
  full_name_remetente:string;
  createdAt: string;
  like:string[];
}
interface Item{
  id:string;
  active:boolean;
  createdAt:string;
  title:string;
  prev_legend:string;
  full_legend:string;
  image_url:string;
}
export default function GridLatestNews() {
  const [viewedOpen, setViewedOpen] = useState(false);
  const [latestNewsTarget, setLatestNewsTarget] = useState<any>();
  const [images, setImages] = useState<any>([]);
  const [edit, setEdit] = useState(false);
  const [titleField, setTitleField] = useState('');
  const [prevLegendField, setPrevLegendField] = useState('');
  const [fullLegend, setFullLegend] = useState<any>(EditorState.createEmpty());
  const [superAdmin, setSuperAdmin] = useState(false);
  const [comment, setComment] = useState("")
  const firstName = localStorage.getItem("firstName")
  const lastName = localStorage.getItem("lastName")
  const idAccount = localStorage.getItem('id')
  const [commentsLatestNews, setCommentsLatestNews] = useState<CommentsLatestNews[]>([])
  const [filterComments, setFilterComments] = useState("most-liked")
  const imageUrl = localStorage.getItem("image_url")

  function compararAtivos(a: { active: any }, b: { active: any }) {
    // Os ativos verdadeiros (true) vêm primeiro
    if (a.active && !b.active) {
      return -1;
    } else if (!a.active && b.active) {
      return 1;
    } else {
      return 0;
    }
  }

  const sortItemsByActiveAndDate = (items: Item[]): Item[] => {
    return items.sort((a, b) => {
      // Primeiro, comparar a propriedade ativo
      if (a.active === b.active) {
        // Se ambos têm o mesmo valor de ativo, comparar pela data createdAt em ordem decrescente
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      }
      // Colocar os itens com ativo: true antes dos itens com ativo: false
      return a.active ? -1 : 1;
    });
  };

  function HandleComment() {
    api.post('/api/v1/comments-latest-news/create', {
      id_remetente: idAccount,
      latest_news_id:latestNewsTarget.id,
      content: comment,
      type:"TEXT",
      full_name_remetente:`${firstName} ${lastName}`
    }).then(() => {
      setComment("")
      getComments(latestNewsTarget.id)
    })
  }
  function getComments(id: string){
    api.get(`/api/v1/comments-latest-news/find/${id}/${filterComments}`).then(r => {
      setCommentsLatestNews(r.data)
    })
  }
  useEffect(() => {
    getComments(latestNewsTarget?.id)
  }, [filterComments])

  function handleOpenLatestTarget(latestNew: any) {
    setViewedOpen(true);
    setPrevLegendField(latestNew.prev_legend);
    const blocksFromHTML = convertFromHTML(latestNew.full_legend);
    setFullLegend(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        )
      )
    );
    getComments(latestNew.id)
    setTitleField(latestNew.title);
    setLatestNewsTarget(latestNew);
  }

  function getImages() {
    axios
      .get('/api/v1/latest-news')
      .then((res) => setImages(res.data.sort(compararAtivos)));
  }

  useEffect(() => {
    getImages();
    handlePermissions()
    return () => {
      const videos = document.querySelectorAll('video');
      videos.forEach((video) => {
        video.play();
      });
    };
  }, []);

  function handleView({ id, active }: any) {
    axios
      .post('/api/v1/latest-news/active-status', {
        id: id,
        active: active === true ? false : true
      })
      .then((res) => {
        setViewedOpen(false);
        getImages();
      });
  }

  function handlePermissions() {
    if (localStorage.getItem('token')) {
      jwt.verify(
        localStorage.getItem('token')!.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        async (err: any, decoded: any) => {
          if (err) {
            alert(err);
          } else {
            const customer_id = localStorage.getItem('id');
            const response = await axios
              .get(`api/v1/accounts/${customer_id}`)
              .then((response) => response.data.results);
            if (response.group_id === '128e5e58-3c0a-4e63-9be1-d0b6fc2f871e') {
              setSuperAdmin(true);
            }
          }
        }
      );
      return;
    }
  }
    
  function handleUpdate() {
    const htmlDescription = draftToHtml(
      convertToRaw(fullLegend.getCurrentContent())
    );

    axios
      .post('/api/v1/latest-news/update', {
        id: latestNewsTarget.id,
        title: titleField,
        prev_legend: latestNewsTarget.prev_legend,
        full_legend: htmlDescription
      })
      .then(() => {
        setEdit(false);
        setViewedOpen(false);
        getImages();
      });
  }
  function deleteLatest() {


    axios
      .delete(`/api/v1/latest-news/delete/${latestNewsTarget.id}`, {
      })
      .then(() => {
        setViewedOpen(false);
        getImages();
      });
  }
  function handleLike(id:string){
    axios
    .post('/api/v1/comments-latest-news/like', {
      id,
      id_remetente:idAccount
    }).then(() => {
      getComments(latestNewsTarget.id)
    })
  }
  function deleteComment(id:string){
    axios
    .delete(`/api/v1/comments-latest-news/delete/${id}`,).then(() => {
      getComments(latestNewsTarget.id)
    })
  }

  if (viewedOpen) {
    return (
      <Container className='t'>
        <Grid>
          <div className='header'>
            <p>{latestNewsTarget?.createdAt.slice(0, 10)}</p>
            {
                (superAdmin && latestNewsTarget.idea)&& (
                  <button className='btn-edit' style={{background: 'purple'}}>
                    Idea Submission
                  </button>
                )
              }
            <div>
            {
                superAdmin && (
                  <button
                  className={latestNewsTarget.active && 'active'}
                  onClick={() =>
                    handleView({
                      id: latestNewsTarget.id,
                      active: latestNewsTarget.active
                    })
                  }
                >
                  {latestNewsTarget.active ? 'Public' : 'Private'}
                </button>
  
                )
                
            }
             
              {
                superAdmin && (
                  <button className='btn-edit' onClick={() => setEdit(true)}>
                    Edit
                  </button>
                )
              }
                {
                superAdmin && (
                  <button className='btn-delete' style={{marginLeft: 8}} onClick={() => deleteLatest()}>
                    Delete
                  </button>
                )
              }
              <button style={{marginLeft:8, background:'#dc6601' }} onClick={() => setViewedOpen(false)}>
                Back to View News
              </button>
            </div>
          </div>
          <div className='content'>
          {!edit ? (<>
            <h1 className='header-title'>{latestNewsTarget.title}</h1>
            { latestNewsTarget.idea &&(<>
              <p>This latest news is an idea submission created by: <strong>{latestNewsTarget?.full_name} - location: {latestNewsTarget.store_id}</strong></p>
             
              <p>Admins Only: Switch to <strong>Public</strong> to publish as a latest news to all users. You can <strong>Edit</strong> before or after if you’d like. </p>
              <br />
              </>
            )}
          </>
           
            ) : (
              <>
                <p>Title: </p>
                <Input
                  value={titleField}
                  onChange={(e) => setTitleField(e.target.value)}
                />
              </>
            )}
            

            {latestNewsTarget.image_url ?( <img src={latestNewsTarget.image_url} alt='' className='t'/>) : (     <video src={latestNewsTarget.video_url} controls={true} className='t' loop ></video>) }
            {edit && (
              <>
                <p>Preview Article: </p>
                <Input
                  value={prevLegendField}
                  onChange={(e) => setPrevLegendField(e.target.value)}
                />
              </>
            )}

            {!edit ? (
              latestNewsTarget.full_legend && (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: draftToHtml(
                        convertToRaw(fullLegend.getCurrentContent())
                      )
                    }}
                    style={{lineHeight:1.3, color:"#303030", margin:32, marginTop:12}}
                   className='full-legend'></div>
             
                </>
              )
            ) : (
              <>
                <p>
                  Full Article:
                </p>

                <Editor
                  editorState={fullLegend}
                  stripPastedStyles={true}
                  wrapperClassName='demo-wrapper'
                  editorClassName='demo-editor'
                  onEditorStateChange={(e) => {
                    setFullLegend(e);
                  }}
                />
              </>
            )}
               {(superAdmin && edit) && (
                <div className='edit-btn'>
                  <button
                    style={{ backgroundColor: 'gray' }}
                    onClick={() => {
                      const blocksFromHTML = convertFromHTML(
                        latestNewsTarget.full_legend
                      );
                      setFullLegend(
                        EditorState.createWithContent(
                          ContentState.createFromBlockArray(
                            blocksFromHTML.contentBlocks,
                            blocksFromHTML.entityMap
                          )
                        )
                      );
                      setEdit(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    style={{ backgroundColor: `${Theme.primary}` }}
                    onClick={() => handleUpdate()}
                  >
                    Update
                  </button>
                </div>
              )}
                 

             <div className='comments'>
              <p style={{fontWeight:'bold'}}>Comment</p>
              <textarea onChange={(e) => setComment(e.target.value)} value={comment} name="" cols={90} placeholder='Write a comment' id=""></textarea>
              <button className='post' onClick={() => HandleComment()}>Post</button>
                <div className="title-comments">
                  <p>Responses</p>
                  <div>
                    <p style={{cursor: "pointer"}} onClick={() => setFilterComments('desc')} className={`${filterComments === 'desc' && 'active'}`}>Newest</p>
                    <p style={{cursor: "pointer"}} onClick={() => setFilterComments('most-liked')}  className={`${filterComments === 'most-liked' && 'active'}`}>Most Liked</p>
                    <p style={{cursor: "pointer"}} onClick={() => setFilterComments('asc')}  className={`${filterComments === 'asc' && 'active'}`}>Oldest</p>
                  </div>
                </div>
                <hr />
                {
                  commentsLatestNews?.map(comment => {
                    return (
                      <>
                      <div key={Math.random()} className='comment-area'>
                      <div>
                        <div className='main-comment'>
                        {
                          (comment.id_remetente === idAccount && imageUrl) ?
                          <img src={imageUrl} alt="" /> :
                          <Avatar style={{display:'flex', flexDirection:"column", justifyContent:"center", alignItems:"center"}}>{comment.full_name_remetente[0]}</Avatar>
                        }
                          <div className='icon-area'>
                          
                           {
                            idAccount && <FaHeart className='icon-gray' style={{cursor:"pointer"}} color={comment.like.includes(idAccount)? 'red':'gray'} size={20} onClick={() => handleLike(comment?.id)}/>
                           }
                                
                          
                            <small>{comment.like.length >= 1 && comment.like.length }</small>
                          </div>
                        
                            <div>
                              <p style={{fontWeight:'bold', color: "#252525"}}>{comment.full_name_remetente}</p>
                              <p>{comment.content}</p>
                            </div>   
                        </div>
                        <div className='comment-edit'>
                          <small style={{color:"#252525", fontWeight:'bold'}}>{semanasOuDiasPassados(comment.createdAt)}</small>  
                          {
                            (superAdmin || comment.id_remetente === idAccount) &&(
                              <BiTrash style={{cursor: 'pointer'}} onClick={() => deleteComment(comment.id)}/>
                            )
                          }
                        </div>
                       
                      </div>
                   
                    </div>
                    <hr />
                    </>
                    )
                  })
                }

              </div>
            <div>
              
                  
            </div>
          </div>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container>
        {images &&
          sortItemsByActiveAndDate(images).map((image: any, index: number) => {
            return (
              <Grid key={Math.random()}>
                <div className='header'>
                  <p>{image.createdAt.slice(0, 10)}</p>
                  {
                    (superAdmin && image.idea) && (
                      <button
                        className={image.idea && 'active'}
                        style={{ background: 'purple' }}
                      >
                        {image.idea && 'Idea Submission' }
                      </button>
                    )
                  }
                  {
                    superAdmin && (
                      <button
                        className={image.active && 'active'}
                        onClick={() =>
                          handleView({
                            id: image.id,
                            active: image.active
                          })
                        }
                      >
                        {image.active ? 'Public' : 'Private'}
                      </button>
                    )
                  }
                 
                </div>
                <div
                  className='content'
                 
                >
                  {image.image_url ?( <img src={image.image_url} alt='' />) : (     <video src={image.video_url} loop controls={true} ></video>) }

                  <h1 className='text'>{image.title}</h1>
                  <p className='text'>{image.prev_legend}</p>
                  <button  onClick={() => {
                    handleOpenLatestTarget(images[index]);
                  }}>Read Full Article</button>
                </div>
              </Grid>
            );
          })}
      </Container>
    );
  }
}
